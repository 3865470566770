  body{
    font-family: Roboto;
  }

  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

svg .svg-elem-1{stroke-dashoffset:520.6141152546031px;stroke-dasharray:520.6141152546031px;fill:transparent;-webkit-transition:stroke-dashoffset 1.5s cubic-bezier(0.215,0.61,0.355,1) 0s,fill 0.7s cubic-bezier(0.47,0,0.745,0.715) 0.8s;transition:stroke-dashoffset 1.5s cubic-bezier(0.215,0.61,0.355,1) 0s,fill 0.7s cubic-bezier(0.47,0,0.745,0.715) 0.8s}svg.active .svg-elem-1{stroke-dashoffset:0;fill:rgb(0,102,153)}svg .svg-elem-2{stroke-dashoffset:388.7113952636719px;stroke-dasharray:388.7113952636719px;-webkit-transition:stroke-dashoffset 1.5s cubic-bezier(0.215,0.61,0.355,1) 0.5s,fill 0.7s cubic-bezier(0.47,0,0.745,0.715) 0.9s;transition:stroke-dashoffset 1.5s cubic-bezier(0.215,0.61,0.355,1) 0.5s,fill 0.7s cubic-bezier(0.47,0,0.745,0.715) 0.9s}svg.active .svg-elem-2{stroke-dashoffset:0}